import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () =>
      import('../views/Home.vue')
  },
  {
    path: '/questionbank',
    name: 'Questionbank',
    component: () =>
      import('../views/Questionbank.vue')
  },
  {
    path: '/addanswer',
    name: 'Addanswer',
    component: () =>
      import('../views/Addanswer.vue')
  },
  {
    path: '/studentinfo',
    name: 'Studentinfo',
    component: () =>
      import('../views/Studentinfo.vue')
  },
  {
    path: '/collectinfo',
    name: 'Collectinfo',
    component: () =>
      import('../views/Collectinfo.vue')
  },
  {
    path: '/examinfo',
    name: 'Examinfo',
    component: () =>
      import('../views/Examinfo.vue')
  },
  {
    path: '/answerlog',
    name: 'Answerlog',
    component: () =>
      import('../views/Answerlog.vue')
  },
  {
    path: '/roominfo',
    name: 'Roominfo',
    component: () =>
      import('../views/Roominfo.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/About.vue')
  }, {
    path: '/answers',
    name: 'Answers',
    component: () =>
      import('../views/Answers.vue')
  },
  {
    path: '/questions',
    name: 'Questions',
    component: () =>
      import('../views/Questions.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () =>
      import('../views/Test.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import('../views/Login.vue')
  },
  {
    path: '*',
    redirect: 'Login'
  }
]

const router = new VueRouter({
  routes
})

export default router
