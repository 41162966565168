<template>
  <div id="app">
    <template v-if="$route.name === 'Login'">
      <router-view></router-view>
    </template>
    <el-container style="height: 100vh; overflow：hidden border: 1px solid #eee" v-else>
      <el-aside width="250px" style=" height:100% background-color: rgb(238, 241, 246)"
        v-show="showLogin">
        <el-col :span="24" style="height:100vh; overflow：hidden">
          <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" @open="handleOpen"
            @close="handleClose" background-color="#545c64" text-color="#fff"
            active-text-color="#ffd04b" style="height: 100%;">
            <el-menu-item index="0" href="#home" @click="$router.push('home')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span slot="title">科目与任务管理</span>
              </template>
            </el-menu-item>
            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>题库管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="1-1" href="#questionbank" icon="el-icon-s-help"
                  @click="$router.push('questionbank')">已有题库</el-menu-item>
                <el-menu-item index="1-2" href="#addanswer" icon="el-icon-s-help"
                  @click="$router.push('addanswer')">
                  补录题库
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item index="3" href="#studentinfo" @click="$router.push('studentinfo')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span slot="title">学生管理</span>
              </template>
            </el-menu-item>
            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>答题管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="2-1" href="#collectinfo" @click="$router.push('collectinfo')">
                  考试名单汇总</el-menu-item>
                <el-menu-item index="2-2" href="#answerlog" @click="$router.push('answerlog')">
                  国开答题记录
                </el-menu-item>
                <el-menu-item index="2-4" href="#examinfo" @click="$router.push('examinfo')">
                  随考作答记录
                </el-menu-item>
                <el-menu-item index="2-3" href="#roominfo" @click="$router.push('roominfo')">
                  考场记录
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-col>
      </el-aside>
      <el-container style="height:100vh;">
        <el-header style="font-size: 12px; background: #409EFF;" v-show="showLogin">
          <span style="font-size: 24px; color: white">题库管理与答题</span>
        </el-header>
        <!-- 身体板块 -->
        <router-view></router-view>
      </el-container>
    </el-container>
  </div>
</template>
<script>
export default {
  data () {
    return {
      showLogin: true,
      activeIndex: '0',
      menuMaps: {
        Home: '0',
        Questionbank: '1-1',
        Addanswer: '1-2',
        Collectinfo: '2-1',
        Answerlog: '2-2',
        Roominfo: '2-3',
        Examinfo: '2-4',
        Studentinfo: '3'
      }
    }
  },
  watch: {
    // 监听页面刷新路径还是在当前
    '$route' (val) {
      // 获取路由地址
      const name = this.$route.name
      this.activeIndex = this.menuMaps[name]
    }
  },
  methods: {
    // 侧边栏展开
    handleOpen: function (key, keyPath) {
    },
    // 侧边栏关闭
    handleClose: function (key, keyPath) {
    },
    beforeunloadFn (e) {
      sessionStorage.removeItem('token')
    }
  },
  created () {
    // window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  }
}
</script>

<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.el-aside {
  color: #333;
}
.disrow {
  display: flex;
  flex-direction: row;
}
</style>
